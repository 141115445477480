var riot = require('riot');
module.exports = 
riot.tag2('slides', '<div if="{slide==0}"> <div riot-style="padding:{top}px 0" class="col-xs-12 col-sm-6"> <h1>Integration</h1> <h2>Creating Value Through Change</h2> </div> <div riot-style="padding:{top-30}px 0 0 0" class="col-xs-12 col-sm-6"> <div class="blobs integration"> <div class="blob1"></div> <div class="blob2"></div> </div> </div> </div> <div if="{slide==1}"> <div riot-style="padding:{top}px 0" class="col-xs-12 col-sm-6"> <h1>Separation</h1> <h2>Creating Value Through Change</h2> </div> <div riot-style="padding:{top-30}px 0 0 0" class="col-xs-12 col-sm-6"> <div class="blobs separation"> <div class="blob1"></div> <div class="blob2"></div> </div> </div> </div> <div if="{slide==2}"> <div riot-style="padding:{top}px 0" class="col-xs-12 col-sm-6"> <h1>Turnaround</h1> <h2>Creating Value Through Change</h2> </div> <div riot-style="padding:{top-30}px 0 0 0" class="col-xs-12 col-sm-6"> <div class="blobs turnaround"> <div class="blob1"></div> </div> </div> </div> <div if="{slide==3}"> <div riot-style="padding:{top}px 0" class="col-xs-12 col-sm-6"> <h1>Transformation</h1> <h2>Creating Value Through Change</h2> </div> <div riot-style="padding:{top-30}px 0 0 0" class="col-xs-12 col-sm-6"> <div class="blobs transformation"> <div class="blob1"></div> </div> </div> </div> <div if="{slide==4}"> <div riot-style="padding:{top}px 0" class="col-xs-12 col-sm-6"> <h1>Portfolio</h1> <h2>Creating Value Through Change</h2> </div> <div riot-style="padding:{top-50}px 0" class="col-xs-12 col-sm-6"> <div class="blobs portfolio"> <div class="blob1"></div> <div class="blob2"></div> <div class="blob3"></div> <div class="blob4"></div> <div class="blob5"></div> <div class="blob6"></div> </div> </div> </div>', '', '', function(opts) {
var inter;

inter = null;

this.slide = 0;

this.top = 100;

this.on('mount', function() {
  this.slide = 0;
  return inter = setInterval(this.switchSlide, 4000);
});

this.on('unmount', function() {
  return clearInterval(inter);
});

this.switchSlide = (function(_this) {
  return function() {
    _this.slide++;
    if (_this.slide > 4) {
      _this.slide = 0;
    }
    return _this.update();
  };
})(this);
});